import React from "react";
import { chunkArray } from "./utils";

export default ({ startDate, endDate, onYearChange }) => {
  let currentYear = startDate.year();
  let years = [];

  while (currentYear <= endDate.year()) {
    years.push(currentYear);
    currentYear++;
  }

  const chunkedYears = chunkArray(years, 3);

  return (
    <table className="rdp-year_grid">
      <tbody className="rdp-quarters" role="rowgroup">

      {chunkedYears.map((chunk, index) => (
        <tr className="rdp-quarter" role="row" key={index}>
          {chunk.map((year, yearIndex) => (
            <td key={yearIndex} role="gridcell" className="rdp-month" data-year={year}>
              <button className="rdp-month_button"
                      tabIndex="-1"
                      aria-label={year}
                      onClick={() => onYearChange(year)}>
                {year}
              </button>
            </td>
          ))}
        </tr>
      ))}

      </tbody>
    </table>
  )
}
