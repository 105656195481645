import moment from "moment/moment";
import React from "react";
import classNames from "classnames";
import { chunkArray } from "./utils";

export default ({ dateMonth, startDate, endDate, onMonthChange }) => {
  const months = moment.months();

  const chunkedMonths = chunkArray(months, 3);

  return (
    <table className="rdp-year_grid">
      <tbody className="rdp-quarters" role="rowgroup">

      {chunkedMonths.map((chunk, quarterIndex) => (
        <tr className="rdp-quarter" role="row" key={quarterIndex}>
          {chunk.map((month, monthIndex) => {
            const index = (3 * quarterIndex) + monthIndex;

            const formattedYear = moment(dateMonth).format('YYYY');
            const x = moment(dateMonth).month(index);

            const startMonth = startDate.startOf('month');
            const endMonth = endDate.endOf('month');
            const startOfMonth = moment(x).clone().startOf('month');
            const endOfMonth = moment(x).clone().endOf('month');
            const disabled = startOfMonth < startMonth || endOfMonth > endMonth ;

            return (
              <td key={monthIndex} role="gridcell"
                  className={classNames('rdp-month', { 'rdp-disabled': disabled })}
                  data-index={index}>
                <button className="rdp-month_button"
                        tabIndex="-1"
                        disabled={disabled}
                        aria-label={`${month} ${formattedYear}`}
                        onClick={() => onMonthChange(index)}>
                  {month}
                </button>
              </td>
          )})}
        </tr>
      ))}

      </tbody>
    </table>
  )
}
